



























































































import Vue from "vue";
export default Vue.extend({
  name: "MenuItem",
  props: {
    icon: {
      type: String
    },
    activeIcon: {
      type: String
    },
    menuTitle: {
      type: String
    },
    routerLink: {
      type: String,
      default: ""
    },
    sub_items: {
      type: Array,
      required: true
    },
    mini_sidebar: {
      type: Boolean,
      required: true
    }
  }
});
