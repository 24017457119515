import { render, staticRenderFns } from "./NavSideBar.vue?vue&type=template&id=e4d1f77a&scoped=true&"
import script from "./NavSideBar.vue?vue&type=script&lang=ts&"
export * from "./NavSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./NavSideBar.vue?vue&type=style&index=0&id=e4d1f77a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4d1f77a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VAppBarNavIcon,VAppBarTitle,VAvatar,VBadge,VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu,VNavigationDrawer,VSpacer})
