var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"color":"background","app":"","elevation":"0","clipped-left":"","id":"navbar","height":"70"}},[_c('v-btn',{staticClass:"sidebar-toggle-icon",class:{
        'sidebar-close-icon': _vm.mini_variant
      },attrs:{"icon":""},on:{"click":_vm.toggle_sidebar}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v(" "+_vm._s(_vm.sidebar_open ? "mdi-chevron-right" : "mdi-chevron-left")+" ")])],1),_c('v-app-bar-nav-icon',{staticClass:"d-md-none",on:{"click":_vm.toggle_mobile_sidebar}}),_c('v-app-bar-title',[_c('img',{staticClass:"hcms-logo",class:{ 'small-hcms-logo': _vm.mini_variant },attrs:{"src":_vm.hcms_logo,"alt":"HCMS Logo"}})]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"mr-10 notification-badge",attrs:{"bordered":"","color":"pink","offset-x":"11.5","offset-y":"19","content":"2","value":"2"}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-bell")])],1),_c('v-menu',{attrs:{"nudge-bottom":"60","rounded":"lg","content-class":"elevation-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-avatar',{attrs:{"width":"42","height":"42"}},[_c('img',{attrs:{"src":_vm.get_user_details.avatar_uri
                    ? _vm.get_user_details.avatar_uri
                    : require('@/assets/profile_pictures/user_placeholder.jpg'),"alt":_vm.get_user_details.first_name}})]),_c('p',{staticClass:"mb-0 ms-5 mr-12 user-name d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.get_user_details.first_name)+" "+_vm._s(_vm.get_user_details.last_name)+" ")]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","to":"/profile"}},[_c('v-list-item-title',[_vm._v("View Profile")])],1),_c('v-list-item',{staticClass:"px-6",on:{"click":_vm.onLogoutClicked}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1),_c('v-navigation-drawer',{staticClass:"sidebar px-`",attrs:{"app":"","clipped":"","left":"","floating":"","mini-variant-width":"90","mini-variant":_vm.mini_variant,"temporary":_vm.is_small_device && _vm.first_time_resize,"mobile-breakpoint":"960"},on:{"update:miniVariant":function($event){_vm.mini_variant=$event},"update:mini-variant":function($event){_vm.mini_variant=$event}},model:{value:(_vm.mob_drawer),callback:function ($$v) {_vm.mob_drawer=$$v},expression:"mob_drawer"}},[_c('v-list',{staticClass:"mt-4 px-2",attrs:{"flat":""}},_vm._l((_vm.sidebar_menu_items),function(item,i){return _c('div',{key:i},[(item.visible)?_c('menu-item',{attrs:{"menu-title":item.menuTitle,"icon":item.icon,"active-icon":item.activeIcon,"router-link":item.routerLink,"sub_items":item.sub_items,"mini_sidebar":_vm.mini_variant}}):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }