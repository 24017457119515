









import Vue from "vue";
import NavSideBar from "@/components/shared/NavSideBar.vue";
export default Vue.extend({
  name: "Dashboard",
  components: { NavSideBar },
  computed: {
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    }
  }
});
