
























































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { ACTION_LOGOUT, GET_USER_DETAILS } from "@/store/modules/auth";
import menuItem from "@/components/shared/MenuItem.vue";
export default Vue.extend({
  name: "NavSideBar",
  data: (): Record<string, any> => ({
    is_small_device: null,
    sidebar_open: null, // For sidebar toggle icon
    hcms_logo: null,
    mini_variant: false,
    sidebar_menu_items: [
      {
        menuTitle: "Dashboard",
        icon: require("@/assets/icons/sidebar/dashboard.svg"),
        activeIcon: require("@/assets/icons/sidebar/active/dashboard.svg"),
        routerLink: "/dashboard",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Dashboard",
        icon: require("@/assets/icons/sidebar/dashboard.svg"),
        activeIcon: require("@/assets/icons/sidebar/active/dashboard.svg"),
        routerLink: "/organization-dashboard",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Skill Extractor",
        icon: require("@/assets/icons/sidebar/skill_extractor.svg"),
        activeIcon: require("@/assets/icons/sidebar/active/skill_extractor.svg"),
        routerLink: "/skill-extractor",
        visible: true,
        sub_items: [
          {
            menuTitle: "Analyzed resumes",
            icon: "mdi-clipboard-list-outline",
            activeIcon: "mdi-clipboard-list",
            routerLink: "/skill-extractor",
            visible: true
          },
          {
            menuTitle: "Resume analysis",
            icon: "mdi-google-analytics",
            activeIcon: "mdi-google-analytics",
            routerLink: "/skill-extractor/resume-analysis",
            visible: true
          }
        ]
      },
      {
        menuTitle: "One to One Matching",
        icon: require("@/assets/icons/sidebar/talent_acquisition.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "/matching",
        visible: true,
        sub_items: [
          {
            menuTitle: "Analyzed Results",
            icon: "mdi-clipboard-list-outline",
            activeIcon: "mdi-clipboard-list",
            routerLink: "/matching/list",
            visible: true
          },
          {
            menuTitle: "New Analysis",
            icon: "mdi-google-analytics",
            activeIcon: "mdi-google-analytics",
            routerLink: "/matching/new",
            visible: true
          }
        ]
      },
      {
        menuTitle: "Candidates",
        icon: require("@/assets/icons/sidebar/candidates.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Career Path",
        icon: require("@/assets/icons/sidebar/career_path.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Organization",
        icon: require("@/assets/icons/sidebar/organization.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Project Team",
        icon: require("@/assets/icons/sidebar/project_team.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Calendar",
        icon: require("@/assets/icons/sidebar/calendar.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Documents",
        icon: require("@/assets/icons/sidebar/documents.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      },
      {
        menuTitle: "Setting",
        icon: require("@/assets/icons/sidebar/settings.svg"),
        activeIcon: require("@/assets/icons/sidebar/settings.svg"), // TODO: Replace active icon
        routerLink: "",
        visible: true,
        sub_items: []
      }
    ],
    mob_drawer: null, // For mobile sidebar
    first_time_resize: false // For mobile sidebar: sidebar always open on first manual resize so using this to hide sidebar on manual resizing
  }),
  components: {
    menuItem
  },
  mounted() {
    const is_sm_and_down = this.$vuetify.breakpoint.smAndDown;
    this.is_small_device = is_sm_and_down;
    this.sidebar_open = !is_sm_and_down;
    if (is_sm_and_down) this.hcms_logo = require("@/assets/logos/hcms.svg");
    else this.hcms_logo = require("@/assets/logos/hcms.svg");
    if (this.get_user_details.company_id === 20) {
      this.sidebar_menu_items = [
        {
          menuTitle: "Dashboard",
          icon: require("@/assets/icons/sidebar/dashboard.svg"),
          activeIcon: require("@/assets/icons/sidebar/active/dashboard.svg"),
          routerLink: "/global-valley",
          visible: true,
          sub_items: []
        },
        {
          menuTitle: "Job Parser",
          icon: require("@/assets/icons/sidebar/documents.svg"),
          activeIcon: require("@/assets/icons/sidebar/settings.svg"),
          routerLink: "/global-valley/job-parser",
          visible: true,
          sub_items: [
            {
              menuTitle: "Job listing",
              icon: "mdi-clipboard-list-outline",
              activeIcon: "mdi-clipboard-list",
              routerLink: "/global-valley/job-parser",
              visible: true
            },
            {
              menuTitle: "Post new job",
              icon: "mdi-cloud-upload-outline",
              activeIcon: "mdi-cloud-upload",
              routerLink: "/global-valley/job-parser/post-job",
              visible: true
            }
          ]
        },
        {
          menuTitle: "Resume Parser",
          icon: require("@/assets/icons/sidebar/documents.svg"),
          activeIcon: require("@/assets/icons/sidebar/settings.svg"),
          routerLink: "/global-valley/resume-parser",
          visible: true,
          sub_items: [
            {
              menuTitle: "Resume listing",
              icon: "mdi-clipboard-list-outline",
              activeIcon: "mdi-clipboard-list",
              routerLink: "/global-valley/resume-parser",
              visible: true
            },
            {
              menuTitle: "Post new resume",
              icon: "mdi-cloud-upload-outline",
              activeIcon: "mdi-cloud-upload",
              routerLink: "/global-valley/resume-parser/post-resume",
              visible: true
            }
          ]
        }
      ];
    } else {
      const dashboard = this.sidebar_menu_items[0];
      const dash_org = this.sidebar_menu_items[1];
      if (this.get_user_details.email === "info@hcms.ai") {
        dashboard.visible = false;
        this.sidebar_menu_items[0] = dashboard;
      }
      if (this.get_user_details.email !== "info@hcms.ai") {
        dash_org.visible = false;
        this.sidebar_menu_items[1] = dash_org;
      }
    }
  },
  watch: {
    mobile(newValue) {
      this.is_small_device = newValue;
      this.is_sidebar_open = !newValue;
      this.mini_variant = false;
      this.first_time_resize = false;
      // If small device
      if (this.is_small_device)
        this.hcms_logo = require("@/assets/logos/hcms.svg");
      else this.hcms_logo = require("@/assets/logos/hcms.svg");
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("auth", {
      logout: ACTION_LOGOUT
    }),
    // Sidebar toggle icon click
    toggle_sidebar() {
      this.sidebar_open = !this.sidebar_open;
      this.mini_variant = !this.mini_variant;
      if (this.sidebar_open)
        this.hcms_logo = require("@/assets/logos/hcms.svg");
      else this.hcms_logo = require("@/assets/logos/hcms.svg");
    },
    // When toggle icon in small devices click
    toggle_mobile_sidebar() {
      this.mob_drawer = !this.mob_drawer;
      this.first_time_resize = true;
    },
    async onLogoutClicked() {
      await this.logout();
    }
  }
});
