var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.sub_items.length === 0)?_c('v-list-item',{staticClass:"font-weight-regular custom-border-radius-16",attrs:{"link":"","exact-active-class":"custom-background-blue","to":_vm.routerLink}},[_c('v-list-item-icon',{class:{
        'mr-0': _vm.mini_sidebar,
        'mr-5': !_vm.mini_sidebar
      }},[(_vm.$route.path === this.routerLink)?_c('v-img',{attrs:{"src":_vm.activeIcon}}):_c('v-img',{attrs:{"src":_vm.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",class:{
          'white--text': _vm.$route.path === this.routerLink,
          'custom-text-black-40': _vm.$route.path !== this.routerLink
        }},[_vm._v(_vm._s(_vm.menuTitle))])],1)],1):_c('v-list-group',{staticClass:"px-0 sub-menus",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"font-weight-regular custom-border-radius-16"},[_c('v-list-item-icon',{class:{
            'mr-0': _vm.mini_sidebar,
            'mr-5': !_vm.mini_sidebar
          }},[_c('v-img',{attrs:{"src":_vm.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap custom-text-black-40"},[_vm._v(_vm._s(_vm.menuTitle))])],1)],1)]},proxy:true}])},_vm._l((_vm.sub_items),function(sub_item,index){return _c('v-list-item',{key:index,staticClass:"font-weight-regular custom-border-radius-16",class:{
        'ml-0': _vm.mini_sidebar,
        'ml-5': !_vm.mini_sidebar
      },attrs:{"link":"","exact-active-class":"custom-background-blue","to":sub_item.routerLink}},[_c('v-list-item-icon',{class:{
          'mr-0': _vm.mini_sidebar,
          'mr-5': !_vm.mini_sidebar
        }},[(_vm.$route.path === sub_item.routerLink)?_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(sub_item.activeIcon))]):_c('v-icon',{staticClass:"custom-text-black-40"},[_vm._v(_vm._s(sub_item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",class:{
            'white--text': _vm.$route.path === sub_item.routerLink,
            'custom-text-black-40': _vm.$route.path !== sub_item.routerLink
          }},[_vm._v(_vm._s(sub_item.menuTitle))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }